import React from 'react'
import Category from '../components/SideBar/Category'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'

class CategoryPage extends React.Component {
  render() {
    const mainCategoryGroup = this.props.data.mainCategoryGroup.group
    const subCategoryGroup = this.props.data.subCategoryGroup.group

    return (
      <Layout>
        <div className="content-menu">
          <Category
            mainCategoryGroup={mainCategoryGroup}
            subCategoryGroup={subCategoryGroup}
          ></Category>
        </div>
      </Layout>
    )
  }
}

export default CategoryPage

export const pageQuery = graphql`
  query {
    mainCategoryGroup: allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___category) {
        mainCategory: fieldValue
      }
    }
    subCategoryGroup: allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___subCategory) {
        subCategory: fieldValue
        nodes {
          frontmatter {
            category
          }
        }
      }
    }
  }
`
